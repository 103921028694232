<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">
            <b-row>
                <b-col cols="12">

                    <b-table-simple striped>
                        <b-thead head-variant="info">
                            <b-th>{{ $t('settings.modules.label.name') }}</b-th>
                            <b-th>{{ $t('settings.modules.label.enabled') }}</b-th>
                            <b-th>{{ $t('settings.modules.label.actions') }}</b-th>
                        </b-thead>
                        <tbody>
                        <b-tr v-for="module in modules" :key="module.id">
                            <b-td>{{ module.name }}</b-td>
                            <b-td>
                                <CBadge :color="module.enabled ? 'success' : 'danger' ">
                                    {{ module.enabled ? $t('common.button.yes') : $t('common.button.no') }}
                                </CBadge>
                            </b-td>
                            <b-td>
                                <b-button
                                    @click="changeEnabled(module)"
                                    size="sm"
                                    class="mr-1"
                                    :variant="module.enabled == 1 ? 'success' : 'danger'"
                                >
                                    <b-icon :icon="module.enabled == 1 ? 'check-circle' : 'x-circle'"></b-icon>
                                </b-button>

                                <b-button
                                    @click="$root.$children[0].openModal('module-modal', {id: module.id},afterSave,{width: '800px'})"
                                    size="sm"
                                    variant="primary"
                                    class="mr-2"
                                    :title="$t('common.title.edit')"
                                >
                                    <font-awesome-icon icon="edit"/>
                                </b-button>
                            </b-td>
                        </b-tr>
                        </tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'Settings-modules',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            modules: [],
        }
    },
    methods: {
        ...mapGetters('Modules', ['getModules']),
        shown() {
            const modulesFetch = this.$store.dispatch('Modules/fetchModules')

            Promise.all([modulesFetch])
                .then(() => {
                    this.modules = this.getModules()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        changeEnabled(module) {
            this.loading = true
            this.$store.dispatch('Modules/saveModule', {id:module.id,enabled:!module.enabled})
                .then((response) => {
                    this.afterSave()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        afterSave() {
            this.$root.afterSettingsUpdate(true, '/settings/modules','modules')
            this.shown()
        },
    },
    mounted() {
        this.shown()
    }
}
</script>